<template>
    <v-container fluid dense>
        <v-row dense class="text-center">
           <v-col class="text-h4 mb-3">Resumo</v-col> 
        </v-row>
        <v-row dense class="mx-3 my-0" v-for="(v,k) in items" :key="`grp${k}`">
            <v-col dense cols="10" class="mx-2 mb-1 pr-4" style="background-color: grey">Total de {{ k }}</v-col>
            <v-col dense class="mx-2 mb-1 pr-4 text-end" style="background-color: grey">{{ v }}</v-col>
        </v-row>
    </v-container>
</template>
<script>
import api from '../../../http';

export default{
    name:'ResumoExpand',
    props: {view:{type:String},url:{type:String}},
    data:()=>({headers:[], items:[], loading:true}),
    methods:{
        async get_listas(url){
            const res = await api.get(`${url}`)
            try{
                const grupos = {};
                grupos['procedimentos gerados'] = 0 
                grupos['procedimentos utilizados'] = 0 
                grupos['procedimentos validados (Evolução + Assinatura)'] = 0 
                grupos['procedimentos disponíveis'] = 0 
                res.data.forEach(item => {
                    if (item.ativo) { // Verifica se o item está ativo
                        if (item.assinatura !== null && item.timestamp !== null) {
                            grupos[`procedimentos validados (Evolução + Assinatura)`]++;
                        }
                        if (item.timestamp !== null) {
                            grupos[`procedimentos utilizados`]++;
                        } else {
                            grupos[`procedimentos disponíveis`]++;
                        }
                        grupos['procedimentos gerados']++;
                    }
                });
                this.items = grupos
            } catch(e) {
                console.log(e)
            } finally {
                this.loading = false
            }
        }

    },
    mounted(){
        this.get_listas(this.url)
    }
}
</script>